<template>
    <div class="layout">
        <Layout>
            <Sider ref="sider" :width="224" :style="{background:'#515a6e',height:'99.5vh'}">
                <a>
                    <img src="../assets/logo.png" alt="logo" height="64"/>
                </a>
                <Menu active-name="home" theme="dark" width="auto" :open-names="[]" @on-select="clickMenuItem">
                    <!-- 首页 -->
                    <MenuItem name="home">
                        <Icon type="md-stats" />
                        首页
                    </MenuItem>
                    <!-- 账号管理 -->
                    <MenuItem name="customerManage">
                        <Icon type="md-people" />
                        账号管理
                    </MenuItem>
                    <Submenu name="channel">
                        <template slot="title">
                            <Icon type="md-cube" />
                            通道管理
                        </template>
                        <MenuItem name="channel-apikey">
                            <Icon type="md-key" />
                            apikey
                        </MenuItem>
                        <MenuItem name="channel-statistics">
                            <Icon type="ios-analytics" />
                            通道统计
                        </MenuItem>
                    </Submenu>
                    <!-- 任务管理 -->
                    <MenuItem name="orderManage">
                        <Icon type="md-paper" />
                        任务管理
                    </MenuItem>
                    <!-- 系统配置 -->
                    <MenuItem name="sysSetting">
                        <Icon type="md-settings" />
                        系统配置
                    </MenuItem>
                </Menu>
            </Sider>
            <Layout :style="{height: '99vh'}">
                <Header class="header">
                    <div class="slogan">
                        「E快讯」客户管理系统
                    </div>
                    <div style="display:flex;align-items: center;">
                        <Tooltip :content="isFullScreen ? '取消全屏':'全屏'" placement="bottom" style="marginTop:8px;">
                            <Icon @click="toggleScreenSize" class="btn-icon" :type="isFullScreen ? 'md-contract':'md-expand'" size="24" color="#515a6e"/>
                        </Tooltip>
                        <Dropdown @on-click="logout" style="marginLeft:24px;">
                            <a>
                                <Avatar style="background-color: #87d068" icon="ios-person" />
                                <span style="font-size:18px;font-weight:bold;"> {{uname}} </span>
                                <Icon type="md-arrow-dropdown" size="16"/>
                            </a>
                            <DropdownMenu slot="list">
                                <DropdownItem>
                                    <Icon type="md-exit" size="16"/> 安全退出
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </Header>
                <Content :style="{marginTop:'6px',padding:'0 16px 16px',minHeight:'280px',minWidth:'900px',background:'#fff'}">
                    <Breadcrumb class="breadcrumb">
                        <BreadcrumbItem >首页</BreadcrumbItem>
                        <BreadcrumbItem v-for="(val, index) of breadcrumb" :key="index">
                            {{mapMenuNameTitle[val] || val}}
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <!-- <keep-alive :exclude="/^(Home|DyRoom)$/" > -->
                    <keep-alive :exclude="['Home', 'CustomerManage', 'ChannelApikey', 'ChannelStatistics', 'OrderManage', 'SysSetting']" >
                        <router-view/>
                    </keep-alive>
                </Content>
            </Layout>
        </Layout>
    </div>
</template>
<script>
export default {
    name: 'Main',
    data () {
        return {
            isCollapsed: false,
            isFullScreen: false,
            uname: '-',
            mapMenuNameTitle: {
                home:           '💯',
                customerManage: '账号管理',
                channel:        '通道管理',
                apikey:         'apikey',
                statistics:     '通道统计',
                orderManage:    '任务管理',
                sysSetting:     '系统配置'
            },
            breadcrumb: ['home']
        }
    },
    watch: {
        '$store.state.breadcrumb': function () {
            this.breadcrumb = this.$store.state.breadcrumb;
        }
    },
    mounted() {
        if (!this.$store.state.hasLogin) {
            this.$Message.error('请登录');
            this.$router.replace('/login');
        } else {
            this.uname = this.$store.state.uinfo.uname;
        }
    },
    methods: {
        clickMenuItem(name) {
            this.breadcrumb = name.split('-');
            this.$router.push('/' + name.replace('-', '/'));
        },
        logout() {
            localStorage.removeItem('token');
            this.$store.commit('logout');
            this.$router.replace('/login');
        },
        toggleScreenSize() {
            if (this.isFullScreen) {
                this.exitFullScreen();
            } else {
                this.fullScreen();
            }
        },
        fullScreen() {
            const el = document.documentElement;
            const rfs = el.requestFullScreen || el.webkitRequestFullScreen || 
                el.mozRequestFullScreen || el.msRequestFullScreen;
            if(rfs) {
                rfs.call(el);
            } else if(window.ActiveXObject) {
                //for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
                const wscript = new ActiveXObject("WScript.Shell");
                if(wscript) {
                    wscript.SendKeys("{F11}");
                }
            } else {
                return;
            }
            this.isFullScreen = true;
        },
        exitFullScreen() {
            const el = document;
            const cfs = el.cancelFullScreen || el.webkitCancelFullScreen || 
                el.mozCancelFullScreen || el.exitFullScreen;
            if(cfs) {
                cfs.call(el);
            } else if(window.ActiveXObject) {
                //for IE，这里和fullScreen相同，模拟按下F11键退出全屏
                const wscript = new ActiveXObject("WScript.Shell");
                if(wscript) {
                    wscript.SendKeys("{F11}");
                }
            } else {
                return;
            }
            this.isFullScreen = false;
        }
    }
}
</script>
<style scoped lang="scss">
    /* 手机 */
    @media screen and (max-width: 768px) {
        .layout { display: inline-block; }
    }
    .layout{
        border: 1px solid #d7dde4;
        background: #f5f7f9;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        .breadcrumb {
            display: flex;
            justify-content: flex-start;
            margin: 24px 0;
        }
    }
    .menu-icon{
        transition: all .3s;
    }
    .rotate-icon{
        transform: rotate(-90deg);
    }
    #btn-collapse:hover {
        color: #5cadff;
        cursor: pointer;
    }
    .btn-icon:hover {
        cursor: pointer;
    }
    .header {
        min-width: 900px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #17233d;
        background-color: #fff;
        box-shadow: 0 2px 3px rgba(0,0,0,.1);
        .slogan {
            flex: 1;
            text-align: center;
            font-size: 18px;
            color: #606266;
        }
    }
    .view-title {
        font-size:20px;
        height:32px;
        line-height:32px;
        color: #17233d;
    }
</style>