import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import * as axios from 'axios';
import * as moment from 'moment';

Vue.use(ViewUI);

Vue.prototype.apiV1 = axios.create({
    baseURL: 'https://api.didisms.shop/v1/a',
    timeout: 0
});
Vue.prototype.apiV1.interceptors.response.use(function (response) {
    const { data: res } = response;
    if (!res.success) {
        Vue.prototype.$Message.error(res.message);
    }
    return response;
}, function (error) {
    const message = error.message instanceof Object ? JSON.stringify(error.message):error.message;
    Vue.prototype.$Message.error(message);
});

moment.locale('zh_CN')
Vue.prototype.moment = moment;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
