import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '../views/Main.vue'

Vue.use(VueRouter)

const routes = [
     {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
      },
      {
        path: '/',
        redirect: '/home',
        name: '_home',
        component: Main,
        children: [
          {
            path: '/home',
            name: 'home',
            component: () => import('../views/Home.vue')
          }
        ]
    },
    {
        path: '/customerManage',
        name: 'customerManage',
        component: Main,
        children: [
          {
            path: '/',
            name: 'customerManage_index',
            component: () => import(/* webpackChunkName: "favorites" */ '../views/CustomerManage.vue')
          }
        ]
    },
    {
        path: '/channel',
        name: 'channel',
        component: Main,
        children: [
          {
            path: 'apikey',
            name: 'apikey',
            component: () => import(/* webpackChunkName: "favorites" */ '../views/channel/Apikey.vue')
          },
          {
            path: 'statistics',
            name: 'statistics',
            component: () => import(/* webpackChunkName: "favorites" */ '../views/channel/Statistics.vue')
          }
        ]
    },
    {
        path: '/orderManage',
        name: 'orderManage',
        component: Main,
        children: [
          {
            path: '/',
            name: 'orderManage_index',
            component: () => import(/* webpackChunkName: "favorites" */ '../views/OrderManage.vue')
          }
        ]
    },
    {
        path: '/sysSetting',
        name: 'sysSetting',
        component: Main,
        children: [
          {
            path: '/',
            name: 'sysSetting_index',
            component: () => import(/* webpackChunkName: "favorites" */ '../views/SysSetting.vue')
          }
        ]
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
